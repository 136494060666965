import {
    Box,
    Button,
    Checkbox, FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment, InputLabel, MenuItem, Select,
    TextField,
    Typography
} from "@material-ui/core";
import * as validation from "../../../shared/validation/validationHelper";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {setValidationErrors} from "../../../shared/errors/errorActions";
import {connect} from "react-redux";
import {ApplicationState} from "../../../ApplicationState";
import * as sharedActions from "../../../shared/sharedActions";
import {ErrorType, ValidationError} from "../../../shared/errors/ErrorModels";
import {InvitedUserProfileModel, ReCaptchaModel, WhiteLabelProfile} from "../../../shared/SharedModels";
import * as actions from "../signUpActions";
import {validationSelector} from "../../../shared/errors/errorSelectors";
import {invitedUserSelector, whiteLabelSelector} from "../../../shared/sharedSelectors";
import {bindActionCreators, Dispatch} from "redux";
import {createStyles, makeStyles} from "@material-ui/core/styles";

interface SignUpFormInlineProps {
    userid: string;
}

type SignUpFormProps = {
    whiteLabel?: WhiteLabelProfile;
    getInvitedUserProfile?: typeof sharedActions.getInvitedUserProfile;
    verifyReCaptcha?: typeof sharedActions.verifyReCaptcha;
    signupInvitedUser?: typeof actions.signupInvitedUser;
    validationErrors?: ValidationError[];
    invitedUser?: InvitedUserProfileModel;
    signup?: typeof actions.signUp;
    serverErrors?: ErrorType | null;
    recaptchaResponse?: ReCaptchaModel;
    getSubscriptionTrialDays?: typeof sharedActions.getSubscriptionTrialDays;
}

const useStyles = makeStyles(() => createStyles({
    btn: {
        backgroundColor: "#F43B3B",
        color: "#fff",
    },
    agreement: {
        fontSize: "0.8rem",
    }
}));

const defaultSignupModel = {
    userName: '',
    organizationName: '',
    email: '',
    password: '',
    password2: '',
    isTermsApproved: false,
    isTermsApproved2: false,
    showPassword: false,
    couponCode: '',
    businessType: ''
};

const SignUpFormComponent: React.FC<SignUpFormProps & SignUpFormInlineProps> = (props) => {
    const classes = useStyles();
    let policyLinks = {
        'termsService': 'https://ratemyservice.io/tos',
        'privacyPolicy': 'https://ratemyservice.io/privacy',
        'cookiePolicy': '' // Cookie policy is combined with the privacy policy
    };
    if (props.whiteLabel) {
        if (props.whiteLabel.termsService) policyLinks.termsService = props.whiteLabel.termsService;
        if (props.whiteLabel.privacyPolicy) policyLinks.privacyPolicy = props.whiteLabel.privacyPolicy;
        if (props.whiteLabel.cookiePolicy) policyLinks.cookiePolicy = props.whiteLabel.cookiePolicy;
    }
    const { userid, invitedUser, signup , serverErrors} = props;
    let validationErrors = props.validationErrors || [];
    const {executeRecaptcha} = useGoogleReCaptcha();
    const [ signupModel, setSignupModel ] = useState(defaultSignupModel);

    const handleReCaptchaVerify = useCallback(async (event) => {
        event.preventDefault();
        if (!executeRecaptcha) {
            setValidationErrors([{name: 'recaptcha', message: 'reCaptcha not yet available'}]);
            return;
        }

        const token = await executeRecaptcha('/signup');
        if (token && props.verifyReCaptcha !== undefined) {
            props.verifyReCaptcha(token);
        }

    }, [executeRecaptcha]);

    useEffect(() => {
        if (props.recaptchaResponse) {
            if (!props.recaptchaResponse.success) {
                setValidationErrors([{name: 'recaptcha', message: 'reCaptcha was not successful'}]);
                return;
            }
            if (props.recaptchaResponse.action !== '/signup' || props.recaptchaResponse.score < 0.6) {
                setValidationErrors([{name: 'recaptcha', message: 'reCaptcha did not pass'}]);
                return;
            }

            submit();
        }
    }, [props.recaptchaResponse])

    useEffect(() => {
        if(userid && !invitedUser && !serverErrors) {
            const userId = Number(userid.replace("id", ""));
            if (props.getInvitedUserProfile) props.getInvitedUserProfile(userId);
        }
    }, []);

    useEffect(() => {
        if(userid && invitedUser) setSignupModel(defaultSignupModel);
    }, [userid, invitedUser]);

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: any }>) => {

        if (!event.target.name) {
            return;
        }

        if (event.target.name === "isTermsApproved") {
            setSignupModel(prev => {
                return {
                    ...prev,
                    isTermsApproved: !prev.isTermsApproved,
                }
            });

            return;
        }

        if (event.target.name === "isTermsApproved2") {
            setSignupModel(prev => {
                return {
                    ...prev,
                    isTermsApproved2: !prev.isTermsApproved2,
                }
            });

            return;
        }

        setSignupModel(prev => {
            return {
                ...prev,
                [event.target.name!]: event.target.value,
            }
        });
    };

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const handleKeyPressPassword = (event: React.KeyboardEvent) => {
        if (event.key === 'Tab') {
            // event.preventDefault();
        }
    };

    const handleClickShowPassword = () => {

        setSignupModel(prev => {
            return {
                ...prev,
                showPassword: !prev.showPassword,
            }
        });
    };

    const submit = () => {

        if(userid && invitedUser && props.signupInvitedUser)
        {
            props.signupInvitedUser({
                userId: Number(userid.replace("id", "")),
                fullName: signupModel.userName,
                password: signupModel.password,
                passwordConfirmation: signupModel.password2,
                isTermsApproved: signupModel.isTermsApproved,
            });
            return;
        }

        if (signup) signup(signupModel);
    }

    const handleSignUpEnterPress = (e: React.KeyboardEvent<HTMLFormElement>) => {
        if(e.key == "Enter") {
            handleReCaptchaVerify(e);
        }
    };

    return (
        <Box className='entry-container'>
            <Box className="entry-option">
                {!window.location.href.includes("/signup/appsumo") && !window.location.href.includes("/UserInvite/")  &&
                    [
                        <Typography className="entry-option-title" variant="h6" color='primary'>
                            { userid && invitedUser ? `Join ${invitedUser.organizationName}` : "Create a new account & try for free" }
                        </Typography>
                    ]
                }
                <Box className='entry-box' justifyContent='space-around'>
                    <Box className='entry-box-item'>
                        <Box className="title" style={{minHeight: "auto"}}>
                            <Typography variant='h6'><strong>Sign Up with Email</strong></Typography>
                        </Box>
                        <Box className='sign-box'>
                            <form onSubmit={handleReCaptchaVerify} onKeyPress={handleSignUpEnterPress}>
                                <TextField className='sign-box-item sign-box-input' name="userName" label="Full Name"
                                           variant="outlined"
                                           value={signupModel.userName}
                                           error={validation.hasError(validationErrors, 'FullName')}
                                           helperText={validation.getErrorText(validationErrors, 'FullName')}
                                           onChange={handleChange}
                                />
                                <TextField className='sign-box-item' name="organizationName" label="Company Name" variant="outlined"
                                           value={signupModel.organizationName}
                                           error={validation.hasError(validationErrors, 'OrganizationName')}
                                           helperText={validation.getErrorText(validationErrors, 'OrganizationName')}
                                           disabled={ !!(userid && invitedUser) }
                                           onChange={handleChange}
                                />
                                {!userid && !invitedUser && !props.whiteLabel && <FormControl variant={"outlined"}>
                                    <InputLabel id="business-type-label">Business Type</InputLabel>
                                    <Select
                                        className='sign-box-item'
                                        labelId="business-type-label"
                                        name="businessType"
                                        value={signupModel.businessType}
                                        onChange={handleChange}
                                        >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={"business"}>Business</MenuItem>
                                        <MenuItem value={"marketing_agency"}>Marketing Agency</MenuItem>
                                    </Select>
                                </FormControl>}
                                <TextField className='sign-box-item' name="email" label="Email" variant="outlined"
                                           value={signupModel.email}
                                           error={validation.hasError(validationErrors, 'Email')}
                                           helperText={validation.getErrorText(validationErrors, 'Email')}
                                           disabled={ !!(userid && invitedUser) }
                                           onChange={handleChange}
                                />
                                <TextField
                                    className='sign-box-item'
                                    label="Password"
                                    type={signupModel.showPassword ? 'text' : 'password'}
                                    name='password'
                                    value={signupModel.password}
                                    variant="outlined"
                                    InputProps ={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    tabIndex="-1"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    onKeyDown={handleKeyPressPassword}
                                                >
                                                    {signupModel.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                    error={validation.hasError(validationErrors, 'Password')}
                                    helperText={validation.getErrorText(validationErrors, 'Password')}
                                    onChange={handleChange}
                                />
                                <TextField
                                    className='sign-box-item'
                                    label="Repeat Password"
                                    type={signupModel.showPassword ? 'text' : 'password'}
                                    name='password2'
                                    value={signupModel.password2}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    tabIndex="-1"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {signupModel.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                    error={validation.hasError(validationErrors, 'PasswordConfirmation')}
                                    helperText={validation.getErrorText(validationErrors, 'PasswordConfirmation')}
                                    onChange={handleChange}
                                />
                                <TextField className='sign-box-item' name="couponCode" label="Coupon Code (Optional)" variant="outlined"
                                           value={signupModel.couponCode}
                                           error={validation.hasError(validationErrors, 'CouponCode')}
                                           helperText={validation.getErrorText(validationErrors, 'CouponCode')}
                                           disabled={ !!(userid && invitedUser) }
                                           onChange={handleChange}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={signupModel.isTermsApproved}
                                            name="isTermsApproved"
                                            color="primary"
                                            required={true}
                                            onChange={handleChange}
                                        />
                                    }
                                    label={
                                        <Box className={classes.agreement}>
                                            <span>I agree to the </span>
                                            <a href={policyLinks.termsService} target="_blank" >Terms of Service</a>
                                            <span>{policyLinks.cookiePolicy ? ',' : ' and '}</span>
                                            <a href={policyLinks.privacyPolicy} target="_blank" >Privacy Policy</a>
                                            {policyLinks.cookiePolicy &&
                                                <>
                                                    <span> and </span>
                                                    <a href={policyLinks.cookiePolicy} target="_blank" >Cookie Policy</a>
                                                </>}
                                        </Box>
                                    }
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={signupModel.isTermsApproved2}
                                            name="isTermsApproved2"
                                            onChange={handleChange}
                                        />
                                    }
                                    className="terms-approved-checkbox"
                                    label={
                                        <Typography>I agree to the Terms of Service and Privacy Policy</Typography>
                                    }
                                />
                                <Button className={`${classes.btn} sign-box-btn`} type="submit" variant="contained">
                                    {!window.location.href.includes("/signup/appsumo") && !window.location.href.includes("/UserInvite/")
                                        ? "START YOUR FREE TRIAL NOW"
                                        : "CREATE ACCOUNT"
                                    }
                                </Button>
                            </form>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Typography style={{ display: "inline" }}>Already have one? </Typography><Link to="/signin">Sign in here</Link>
        </Box>
    );
}

const mapStateToProps = (state: ApplicationState, ownProps: SignUpFormInlineProps) => {
    return {
        userid: ownProps.userid,
        whiteLabel: whiteLabelSelector(state),
        validationErrors: validationSelector(state),
        invitedUser: invitedUserSelector(state),
        recaptchaResponse: state.globals.reCaptchaResponse,
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        signup: actions.signUp,
        signupInvitedUser: actions.signupInvitedUser,
        getInvitedUserProfile: sharedActions.getInvitedUserProfile,
        verifyReCaptcha: sharedActions.verifyReCaptcha,
        getSubscriptionTrialDays: sharedActions.getSubscriptionTrialDays,
    }, dispatch);
};

const SignUpForm = connect(mapStateToProps, mapDispatchToProps)(SignUpFormComponent);

export default SignUpForm;